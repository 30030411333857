import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import React from 'react';

import styles from './banner.module.scss';

interface BannerProps {
  name: string;
  imagePath: string;
  backgroundColor: string;
  handleClick: () => void;
}

const Banner = ({ name, imagePath, backgroundColor, handleClick }: BannerProps) => {
  return (
    <button
      className={styles.banner}
      style={{ backgroundColor: backgroundColor }}
      onClick={() => {
        handleClick();
      }}
    >
      <img className={styles.bannerImage} src={imagePath} alt={name} />

      <Typography className={styles.text} type={TypographyType.TitleExtraLarge}>
        {name}
      </Typography>
    </button>
  );
};

export default Banner;
