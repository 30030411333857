import { Dialog, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { localStorage, useI18N } from '@vivino/js-web-common';
import React, { useEffect, useState } from 'react';

import Banner from './Banner';
import styles from './contact.module.scss';
import BellDoodle from './doodles/BellDoodle';
import LocationDoodle from './doodles/LocationDoodle';
import PercentageDoodle from './doodles/PercentageDoodle';
import SupportDoodle from './doodles/SupportDoodle';
import customerImage from './images/customer.png';
import merchantImage from './images/merchant.png';
import wineryImage from './images/winery.png';

const TRANSLATIONS_PATH = 'contact.index';

const TRANSLATIONS = {
  contactUs: `${TRANSLATIONS_PATH}.contact_us`,
  whoAreYou: `${TRANSLATIONS_PATH}.who_are_you`,
  chatInstructions: `${TRANSLATIONS_PATH}.chat_instructions`,
  howToContactUs: `${TRANSLATIONS_PATH}.how_to_contact_us`,
  banners: {
    customer: `${TRANSLATIONS_PATH}.banners.customer`,
    merchant: `${TRANSLATIONS_PATH}.banners.merchant`,
    winery: `${TRANSLATIONS_PATH}.banners.winery`,
  },
  contactDetails: `${TRANSLATIONS_PATH}.contact_details`,
  usAddress: {
    header: `${TRANSLATIONS_PATH}.us.header`,
    address_line: `${TRANSLATIONS_PATH}.us.address_line`,
    address_line1: `${TRANSLATIONS_PATH}.us.address_line1`,
  },
  dkAddress: {
    header: `${TRANSLATIONS_PATH}.dk.header`,
    address_line: `${TRANSLATIONS_PATH}.dk.address_line`,
    address_line1: `${TRANSLATIONS_PATH}.dk.address_line1`,
    address_line2: `${TRANSLATIONS_PATH}.dk.address_line2`,
  },
  security: {
    report: `${TRANSLATIONS_PATH}.security.report`,
    submit_your_findings: `${TRANSLATIONS_PATH}.security.submit_your_findings`,
  },
  retail: {
    email: `${TRANSLATIONS_PATH}.retail.email`,
    header: `${TRANSLATIONS_PATH}.retail.header`,
    text: `${TRANSLATIONS_PATH}.retail.text`,
  },
  advertising: {
    email: `${TRANSLATIONS_PATH}.advertising.email`,
    header: `${TRANSLATIONS_PATH}.advertising.header`,
    text: `${TRANSLATIONS_PATH}.advertising.text`,
  },
} as const;

const VISITOR_WORKFLOW = 'visitor_workflow';
const visitorWorkflow = localStorage.getItem(VISITOR_WORKFLOW);
const hasUrlparam = location.search.includes('workflow');

const Contact = () => {
  const [open, setOpen] = useState(!visitorWorkflow && !hasUrlparam);
  const disclosureForm = 'https://forms.gle/wj3B2WdgAqY8LSNW6';

  const { t } = useI18N();

  const handleOnClick = (name: string) => {
    localStorage.setItem(VISITOR_WORKFLOW, name);
    location.href = `/contact/?workflow=${name}`;
  };

  useEffect(() => {
    if (visitorWorkflow && !hasUrlparam) {
      location.href = `/contact/?workflow=${visitorWorkflow}`;
    }
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.title} component="h1" type={TypographyType.HeadlineLarge}>
          {t(TRANSLATIONS.contactUs)}
        </Typography>
      </div>

      <div className={styles.content}>
        <div className={styles.banners}>
          <Banner
            name={t(TRANSLATIONS.banners.customer)}
            imagePath={customerImage}
            backgroundColor="#fef5dc"
            handleClick={() => handleOnClick('customer')}
          />
          <Banner
            name={t(TRANSLATIONS.banners.merchant)}
            imagePath={merchantImage}
            backgroundColor="#e6ead6"
            handleClick={() => handleOnClick('merchant')}
          />
          <Banner
            name={t(TRANSLATIONS.banners.winery)}
            imagePath={wineryImage}
            backgroundColor="#e9e7f0"
            handleClick={() => handleOnClick('winery')}
          />
        </div>
        <div className={styles.chatInstructions}>
          <div className={styles.sectionName}>
            <SupportDoodle />
            <Typography type={TypographyType.TitleExtraLarge}>
              {t(TRANSLATIONS.howToContactUs)}
            </Typography>
          </div>
          <Typography gutterBottom type={TypographyType.BodyMedium}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: t(TRANSLATIONS.chatInstructions, {
                  customerUrl: '/contact/?workflow=customer',
                  merchantUrl: '/contact/?workflow=merchant',
                  wineryUrl: '/contact/?workflow=winery',
                }),
              }}
            ></div>
          </Typography>
        </div>

        <hr />

        <div className={styles.contactInfo}>
          <div className={styles.sectionName}>
            <PercentageDoodle />
            <Typography className={styles.text} type={TypographyType.TitleExtraLarge}>
              {t(TRANSLATIONS.retail.header)}
            </Typography>
          </div>

          <Typography
            className={styles.securityText}
            type={TypographyType.BodyMedium}
            dangerouslySetInnerHTML={{
              __html: t(TRANSLATIONS.retail.text, {
                email: t(TRANSLATIONS.retail.email),
              }),
            }}
          />
        </div>

        <hr />

        <div className={styles.contactInfo}>
          <div className={styles.sectionName}>
            <BellDoodle />
            <Typography className={styles.text} type={TypographyType.TitleExtraLarge}>
              {t(TRANSLATIONS.advertising.header)}
            </Typography>
          </div>

          <Typography
            className={styles.securityText}
            type={TypographyType.BodyMedium}
            dangerouslySetInnerHTML={{
              __html: t(TRANSLATIONS.advertising.text, {
                email: t(TRANSLATIONS.advertising.email),
              }),
            }}
          />
        </div>

        <hr />

        <div className={styles.contactInfo}>
          <div className={styles.contactInfoItem}>
            <div className={styles.sectionName}>
              <LocationDoodle />
              <Typography className={styles.text} type={TypographyType.TitleExtraLarge}>
                {t(TRANSLATIONS.usAddress.header)}
              </Typography>
            </div>
            <address className={styles.address}>
              <Typography type={TypographyType.BodyMedium}>
                {t(TRANSLATIONS.usAddress.address_line)}
              </Typography>
              <Typography type={TypographyType.BodyMedium}>
                {t(TRANSLATIONS.usAddress.address_line1)}
              </Typography>
            </address>
          </div>

          <div className={styles.contactInfoItem}>
            <div className={styles.sectionName}>
              <LocationDoodle />
              <Typography className={styles.text} type={TypographyType.TitleExtraLarge}>
                {t(TRANSLATIONS.dkAddress.header)}
              </Typography>
            </div>
            <address className={styles.address}>
              <Typography type={TypographyType.BodyMedium}>
                {t(TRANSLATIONS.dkAddress.address_line)}
              </Typography>
              <Typography type={TypographyType.BodyMedium}>
                {t(TRANSLATIONS.dkAddress.address_line1)}
              </Typography>
              <Typography type={TypographyType.BodyMedium}>
                {t(TRANSLATIONS.dkAddress.address_line2)}
              </Typography>
            </address>
          </div>
        </div>

        <hr />

        <div className={styles.reportSecurityIssue}>
          <div className={styles.sectionName}>
            <SupportDoodle />
            <Typography className={styles.text} type={TypographyType.TitleExtraLarge}>
              {t(TRANSLATIONS.security.report)}
            </Typography>
          </div>

          <Typography
            className={styles.securityText}
            type={TypographyType.BodyMedium}
            dangerouslySetInnerHTML={{
              __html: t(TRANSLATIONS.security.submit_your_findings, {
                disclosure_form: disclosureForm,
              }),
            }}
          />
        </div>
      </div>

      <Dialog
        dismissable
        open={open}
        maxWidth="md"
        dialogTitle={t(TRANSLATIONS.whoAreYou)}
        onClose={() => setOpen(false)}
      >
        <div>
          <div className={styles.banners}>
            <Banner
              name={t(TRANSLATIONS.banners.customer)}
              imagePath={customerImage}
              backgroundColor="#fef5dc"
              handleClick={() => handleOnClick('customer')}
            />
            <Banner
              name={t(TRANSLATIONS.banners.merchant)}
              imagePath={merchantImage}
              backgroundColor="#e6ead6"
              handleClick={() => handleOnClick('merchant')}
            />
            <Banner
              name={t(TRANSLATIONS.banners.winery)}
              imagePath={wineryImage}
              backgroundColor="#e9e7f0"
              handleClick={() => handleOnClick('winery')}
            />
          </div>
        </div>
      </Dialog>
    </section>
  );
};

export default Contact;
